//#region 
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
//Import Style
import './Contact.css';
//#endregion

const Contact = () => {

    //#region Variables
    const email = 'contact@recibits.co'
    //#endregion

    //#region Functions
    const handleClick = (event) => {
        event.preventDefault();
        window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`);
    };
    //#endregion

    return(
        <IonPage>
            <IonHeader class='contact_header'>
                <NavBar></NavBar>
            </IonHeader>
            <IonContent className="contact_content">
                <div className='contactus_background'></div>
                    <div className='contactus_background_img'></div>
                    <div className="story">
                        <h2 className="contactTitle">Contact Details</h2> 
                        <br></br> <br></br>
                        <h4 className="contact_title">Please dont hesitate to contact us through the folowing email:</h4>
                        <br></br>
                        <div onClick={handleClick} className="contact_email">contact@recibits.co</div><br></br>
                    </div>
                <Footer></Footer>
            </IonContent>
        </IonPage>
    )

}

export default Contact;