import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pages */
import Home from './pages/Home';
import RecipePage from './pages/RecipePage';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Verify from './pages/Verify';
import ForgetPass from './pages/ForgetPass';
import ChangePass from './pages/ChangePass';
import ResetPass from './pages/ResetPass';
import Contact from './pages/Contact';
import Premium from './pages/Premium'
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
// import LoginAr from './pages/LoginAr';
// import AboutUsAr from './pages/AboutUSAr';
// import ContactAr from './pages/ContactAr';
// import PrivacyAr from './pages/PrivacyAr';
// import TermsAr from "./pages/TermsAr"

setupIonicReact();

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/recipe/:id/:name">
          <RecipePage/>
        </Route>
        <Route exact path="/home/aboutus">
          <AboutUs/>
        </Route>
        <Route exact path="/home/contact">
          <Contact/>
        </Route>
        <Route exact path="/premium">
          <Premium/>
        </Route>
        <Route exact path="/login">
          <Login/>
        </Route>
        <Route exact path="/signup">
          <SignUp/>
        </Route>
        <Route exact path="/home/verify">
          <Verify/>
        </Route>
        <Route path="/home/forgetpass">
          <ForgetPass/>
        </Route>
        <Route exact path="/home/ChangePass">
          <ChangePass/>
        </Route>
        <Route exact path="/home/ResetPass">
          <ResetPass/>
        </Route>
        <Route exact path="/home/Privacy">
          <Privacy/>
        </Route>
        <Route exact path="/home/Terms">
          <Terms/>
        </Route>
        {/* <Route exact path="/loginAr">
          <LoginAr/>
        </Route>
        <Route exact path="/home/aboutusar">
          <AboutUsAr/>
        </Route>
        <Route exact path="/home/contactar"> 
          <ContactAr/>
        </Route>
        <Route exact path = "/home/PrivacyAr">
          <PrivacyAr/>
        </Route>
        <Route exact path = "/home/TermsAr">
          <TermsAr/>
        </Route> */}
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);


export default App;
