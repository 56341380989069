import RecipesReducer from "./RecipesReducer"
import {combineReducers} from 'redux';

const allReducers = combineReducers({
    recipes : RecipesReducer,
})

const rootReducer = (state, action) => {
return allReducers(state, action)
}

export default rootReducer