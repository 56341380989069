//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
//Import Axios
import axios from 'axios'
//Import React Components
import { useEffect, useRef} from 'react';
//Import Style
import './form.css'
//Import Resources
import logo from '../resources/logo/logo_transparent_edited.png' 
//#endregion


const ForgetPass = () => {

    //#region Variables
    const emailRef = useRef(null);
    const serverIP = "localhost"
    //#endregion

    //#region Functions
    const sendEmail = async (email) => {
        await axios.get(`http://${serverIP}:3002/api/verify?email=${encodeURIComponent(email)}`)
            .then(async (result) => {
        })
    }
    //#endregion


    //#region Use Effect
    useEffect(()=> {

        const fetchCsrfToken = async () => {
            try {
                await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
            });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
                .then(response => {
                    console.log('Tokens set');
                })
                .catch(error => {
                    console.error('Error setting tokens:', error);
            });
        }

        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };
    },[]) 
    //#endregion   



    return(
        <IonPage >
            <IonHeader className='form_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent fullscreen>

            <div className='form_background'></div>
            <div className='form_background_img'></div>
            

                <div className="form_main">
                    <div className="form">
                            <img src={logo} className="form_logo"></img>
                            <div className="form_label">Enter the email that is associated with your account</div>
                            <IonInput ref={emailRef} className="form_input" label="Email" type="email" labelPlacement="floating" fill="outline" placeholder="Enter Your Email"></IonInput>
                            <IonButton className='form_btn'color="secondary" onClick={() => sendEmail(emailRef.current.value)}>Submit</IonButton>
                    </div>

                </div>
            </IonContent>
        </IonPage>
    )
    }



export default ForgetPass;