import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonImg, IonIcon } from '@ionic/react';
import './premiumRecipeCard.css';
import pic from '../resources/recipes/chorizo-mozarella-gnocchi-bake-cropped.jpg'
import plate1 from '../resources/recipes/rice.png'
import plate2 from '../resources/special.png'
import crown from '../resources/crown.png'
import { peopleOutline, speedometerOutline, timeOutline } from 'ionicons/icons';
// import imgg from 'drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ'

const PremiumRecipeCard = (recipe) => {

    // console.log(recipe.recipe.state);
    if(recipe.recipe.state.images != null){
      // console.log(recipe.recipe.state.images[0])
    }

    // const img = require("drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ")
    return(


      <div className='p_recipe_card' onClick={()=>{recipe.onClickRecipe(recipe.recipe.state.id)}}>
          <div className="p_image-container">
            <img src={crown}></img>
          </div>
        { recipe.recipe.state.images==null ?(
          <img className='p_recipe_card_image' src={plate2}></img>
        ):(
          <img className='p_recipe_card_image' src={recipe.recipe.state.images[0]}></img>
         )}
        {/* <img className='recipe_card_image' src={plate1}></img> */}

        <div className='p_recipe_card_header'>
          {recipe.recipe.getTitle()}
        </div>

        <div className='p_recipe_card_footer'>
          <div className='p_footer_btn_left'>
            <IonIcon className='p_footer_icon' icon={timeOutline}/>
            <div className='p_footer_text'>{recipe.recipe.getTime() ? recipe.recipe.getTime(): '-'}</div>
          </div>
          <div className='p_footer_btn_middle'>
            <IonIcon className='p_footer_icon' icon={peopleOutline}/>
            <div className='p_footer_text'>{recipe.recipe.getPortion() ? recipe.recipe.getPortion(): '-'}</div>
          </div>
          <div className='p_footer_btn_right'>
            <IonIcon className='p_footer_icon' icon={speedometerOutline}/>
            <div className='p_footer_text'>{recipe.recipe.getDifficulty() ? recipe.recipe.getDifficulty(): '-'}</div>
          </div>
        </div>

      </div>


    )
    
}

export default PremiumRecipeCard;