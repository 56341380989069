//#region Imports
//Import Ionic Components
import {  IonContent, IonHeader, IonPage, IonIcon } from '@ionic/react';
// Import Components
import NavBar from '../components/NavBar';
import Opinion from '../components/Opinion';
//Import Ion Icons
import { peopleOutline, speedometerOutline, timeOutline } from 'ionicons/icons';
//Import React Components
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
//Import Classes
import Recipe from '../components/Recipe';
//Import Lightbox
import Lightbox from "yet-another-react-lightbox";
import {Counter, Download, Fullscreen, Zoom, Thumbnails} from "yet-another-react-lightbox/plugins";
//Import Resources
import starOff from '../resources/StarOff.png'
import starOn from '../resources/StarOn.png'
import Footer from '../components/Footer';
//Import Axios
import axios from 'axios'
//Import Style
import './RecipePage.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
//#endregion




const RecipePage = () => {


//#region States
  const location = useLocation();
  const serverIP = "localhost"
  const receivedData = location.state && location.state.data;
  const [ready, setIsReady] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [recipe, setRecipe] = useState(null)
  const [favBool, setfavBool] = useState(false)
  const [open, setOpen] = useState(false)
  const [slides, setSlides] = useState([])
  const [initialSlide, setInitialSlide] = useState()
  const { id, name } = useParams();
  const [userPremium, setUserPremium] = useState(false)
  const [error, setError] = useState(null)
  const [isLoggedin, setisLoggedin] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [personId, setPersonId] = useState(null)
  const history = useHistory();
  const [countries, setCountries] = useState([])
//#endregion




 

  //#region Functions
    //Login
    const onLoginClick = () => {
      history.replace({
        pathname: '/login'
      })
    }

  //Subscribe
    const onSubscribeClick = () => {
      if(isLoggedin){
        //const dataSend = {
        //  id:personId
        //}
        history.replace({
          pathname: '/premium',
          //state: {data: dataSend}
        })
      }
      else{
        history.replace({
          pathname: '/login'
        })
      }
    }

    const onLogout = async () => {
      await axios.post(`http://${serverIP}:3002/api/logout`, {}, { withCredentials: true });
      setisLoggedin(false)
      setShowAccount(false)
      setfavBool(false)
      //setPersonId(null)
      //setUserPremium(false)
      history.replace({
        pathname: '/'
      })
    }

  const stateFavourite = async () => {
    setfavBool(true)
    const result = {
      personid: personId,
      recipeid: recipe.rid
    }
    await axios.post(`http://${serverIP}:3002/api/insertFavourite`, result,{ withCredentials: true })  
            .then(response => {
              console.log(response);
            })
            .catch(error => {
              console.log(error);
            })
  }

  const RevstateFavourite = async () => {
    setfavBool(false)
    const result = {
      personid: personId,
      recipeid: recipe.rid
    }
    await axios.post(`http://${serverIP}:3002/api/deleteFavourite`, result,{ withCredentials: true })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        })
  }

  const openbox = (i) =>{
    setOpen(true)
    setSlides(recipe.images.map(image => ({ src: image })));
    setInitialSlide(i)
  }

  //#endregion Functions

//#region use Effect
  useEffect(() => {
    
    setIsReady(false)
    setisLoading(true)

    const fetchCsrfToken = async () => {
      try {
          await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
      } catch (error) {
          console.error('Error fetching CSRF token:', error);
      }
      };

  const intervalId = setInterval(() => {
      // Call the refresh token endpoint to renew the access token
      axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
          .then(response => {
          // console.log('Access token refreshed');
          })
          .catch(error => {
          // console.error('Error refreshing token:', error);
          });
      }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
      
  
      const authenticateAPI = async () => {  
      await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
      .then(response => {
          console.log('Tokens set');
      })
      .catch(error => {
          console.error('Error setting tokens:', error);
      });
      }

    //Check cookies for log in info and save the id
    const getProtectedResource = async () => {
      try {
        const response = await axios.get(`http://${serverIP}:3002/api/protected`, { withCredentials: true });
        setisLoggedin(true)
        setShowAccount(true)
        setPersonId(response.data)
        getPremiumInfo(response.data)
      } catch (error) {
        if (error.response && error.response.status === 401) { // Auth Cookie not found
          try {
            await axios.post(`http://${serverIP}:3002/api/refresh`, {}, { withCredentials: true });
            const retry = await axios.get(`http://${serverIP}:3002/api/protected`,{ withCredentials: true });
            setisLoggedin(true)
            setShowAccount(true)
            setPersonId(retry.data)
            getPremiumInfo(retry.data)
          } catch (refreshError) { // No refresh cookie found
            console.log(refreshError)
            return
          }
        }
      }
    };




    const getCountries = async () => {
      var countryList = [];
      await axios.get(`http://${serverIP}:3002/api/country`,{ withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    result.data.forEach(dt => { 
                        countryList.push({id: dt.id, name: dt.origin});
                    }) 
                }).finally(() => {
                    setCountries(countryList)
                    
                }).catch((err) => {
                    setError(err)
                    console.log(err)
                })    
                            
    }

    const getPremiumInfo = async (id) => {
      await axios.get(`http://${serverIP}:3002/api/checkPremium?id=${encodeURIComponent(id)}`,{ withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    if(result.data)
                      setUserPremium(true)
                }).catch((err) => {
                    setError(err)
                });
    }

    
    const fetchRecipe = async () => {
      try {
        const recipeId = parseInt(id, 10);
        console.log(recipeId)
        console.log(`http://${serverIP}:3002/api/getInfo?rid=${encodeURIComponent(recipeId)}`)
        await axios.get(`http://${serverIP}:3002/api/getInfo?rid=${encodeURIComponent(recipeId)}`,{ withCredentials: true })
                  .then((result) => {
                      if(result.data == 'no data'){
                        console.log(result)
                          return
                      }
                      console.log(result)
                      
                      var reci = new Recipe(0,result.data[0].rid,result.data[0].name,result.data[0].title,result.data[0].subtitle,result.data[0].description,result.data[0].steps,result.data[0].images,result.data[0].category,result.data[0].visible,result.data[0].ingredients,result.data[0].time,result.data[0].portion,result.data[0].origin,result.data[0].difficulty)
                      setRecipe(reci.state)
                      console.log(reci.state)
                      
                  }).finally(()=>{
                    console.log(personId)
                  }).catch((err) => {
                      console.log("getting Recipes error")
                      console.log(err)
                  });
       
      } catch (err) {
        // setError('An error occurred while fetching the recipe');
      } finally {
        // setLoading(false); 
      }
    };

   
    const loadData = async () => {
      await fetchCsrfToken()
      await authenticateAPI()
      await getProtectedResource()
      fetchRecipe()
      getCountries()
      // setIsReady(true)
      // setisLoading(false)
      setIsReady(true)
      setisLoading(false)
    }

    loadData()


    return () => {
      clearInterval(intervalId); 
    };
    
    
  }, []);

  useEffect(()=>{
    const isFavourite = async (pid,rid) => {
      console.log(`http://${serverIP}:3002/api/isfavourite?pid=${encodeURIComponent(pid)}&rid=${encodeURIComponent(rid)}`)
      await axios.get(`http://${serverIP}:3002/api/isfavourite?pid=${encodeURIComponent(pid)}&rid=${encodeURIComponent(rid)}`,{ withCredentials: true })
                .then((result) => {
                  console.log(result)
                    if(result.data == 'no data'){
                      console.log("no data")
                        return
                    }
                    if(result.data){
                      console.log(result)
                      setfavBool(result.data)
                    }
                }).finally(()=>{
                  
                }).catch((err) => {
                    setError(err)
                });
    }

    if(recipe && personId){
      console.log("lol")
      isFavourite(personId,recipe.rid)
    }

  }, [recipe, personId])
//#endregion



//#region UI
  return (
    <>
     {ready && !isLoading && recipe ? 
    
    <IonPage className='recipe_page'>
     
      <IonHeader class='recipe_header'>
          <NavBar onLoginClick={onLoginClick} onSubscribeClick={onSubscribeClick}  showAccount={showAccount} onLogout={onLogout} showLogin={!isLoggedin}></NavBar>
      </IonHeader>

      <IonContent fullscreen className='recipe_content'>

      <div className='recipe_background'></div>
      <div className='recipe_background_img'></div>


       { recipe!=0 ? (

        <div className='recipe_container'>


          {/* Recipe Introduction */}
          <div className='recipe_intro'>

            {/* Recipe Left Information (Image, cards) */}
            <div className="recipe_img_div">
              { recipe && Array.isArray(recipe.images) ?
                <img className="recipe_image" src={recipe.images[0]} ></img>
              :null}
              <div className='recipe_info_card'>
                <div className='recipe_info_title'>
                  <IonIcon className='recipe_info_card_icon' icon={timeOutline}></IonIcon>
                  <span className='recipe_info_card_info'>Time</span>
                </div>
                <span className='recipe_info_card_value'>{recipe.time ? recipe.time: '-'}</span>
                </div>
              <div className='recipe_info_card'>
                <div className='recipe_info_title'>
                    <IonIcon className='recipe_info_card_icon' icon={peopleOutline}></IonIcon>
                    <span className='recipe_info_card_info'>Portion</span>
                  </div>
                  <span className='recipe_info_card_value'>{recipe.portion ? recipe.portion: '-'}</span>
                </div>
              <div className='recipe_info_card'>
                <div className='recipe_info_title'>
                      <IonIcon className='recipe_info_card_icon' icon={speedometerOutline}></IonIcon>
                      <span className='recipe_info_card_info'>Difficulty</span>
                    </div>
                    <span className='recipe_info_card_value'>{recipe.difficulty ? recipe.difficulty: '-'}</span>
                </div>
            </div>


            {/* Recipe Right Information (description, tags) */}
            <div className='recipe_desc_div'>
              <div className='recipe_desc_title'>
              <span>{recipe.name}</span>
              {!favBool && (<img onClick={stateFavourite} className="recipe_desc_star" src={starOff}></img>)}
              {favBool && (<img onClick={RevstateFavourite} className="recipe_desc_star" src={starOn}></img>)} 
              </div>
              <span className='recipe_desc_subtitle'>Subclass:  {recipe.subTitle}</span>
              <span className='recipe_desc_category'>Category:  {recipe.category}</span>
              <span className='recipe_desc_country'>Country: {recipe.origin} </span>
              <span className='recipe_desc_description'>{recipe.description}</span>
            </div>


          </div>

          

          
          {/* Ingredients */}
          <div className='recipe_ingredients'>
            <div className='recipe_ing_title'>Ingredients</div>
            <div className='recipe_ing_list'>
            {recipe.ingredients && ready ? recipe.ingredients.map((r,i)=>{return(           
              <span key={i} className='recipe_ing'>{'•     '+r}</span>           
            )}): null}
            </div>
          </div>


          {/* Steps */}
          <div className='recipe_steps'>
          <div className='recipe_steps_title'>Steps</div>         
            <div className='recipe_steps_list'>
            { recipe && Array.isArray(recipe.images) && ready ? recipe.steps.map((s,i)=> {return(             
                <span key={i} className='recipe_step'>{(i+1)+')     '+s}</span>            
            )}) : null
            }
            </div>
          </div>
          

          {/* Gallery */}
          <div className='gallery_container'>
              <div className="inner_gallery_container">
                { recipe && Array.isArray(recipe.images) && ready ? recipe.images.map((s,i) => {
                  if(i != 0)
                  return(
                    <div key={i} className="gallery_item">
                      <img onClick={() => openbox(i)} className="gallery_img" src={recipe.images[i]}></img>
                    </div>
                  )
                }) : null}                
              </div>
          </div>

          
           
              


        <Lightbox 
        open={open}
        plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        close={() => setOpen(false)}
        slides={slides}
        index={initialSlide}></Lightbox>

        
          {/* Opinion Box */}
          <div>
          <Opinion></Opinion>
          </div>


                  
          


          
        </div>
       ) : null }

        <Footer></Footer>

      </IonContent>
            
    </IonPage>
          
    :null
    }
</>
   
  )
//#endregion



}


export default RecipePage;