//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage } from "@ionic/react"
// Import Components
import NavBar from "../components/NavBar";
//Import Axios
import axios from 'axios'
//Import React Components
import { useEffect, useRef} from 'react';
import bcrypt from "bcryptjs-react";
//Import Style
import './form.css'
//Import Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//#endregion

const ChangePass = () => {

    //#region Variables
    const serverIP = "localhost"
    var urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const username = urlParams.get('username');
    const PassRef = useRef(null);
    const ConPassRef = useRef(null);
    //#endregion


    //#region Functions
    const ChangePassword = async (pass, cPass, email, username) => {
        if(pass == cPass){
            await axios.get(`http://${serverIP}:3002/api/credentials?username=${encodeURIComponent(username)}`)
                .then(async (result) => {
                    if(result.data.length == 0){
                        alert('Incorrect Username');
                        console.log(username);
                        console.log(email)
                    }
                    else{
                        const isEmailValid = await bcrypt.compare(result.data[0].email,email);
                        console.log(result.data[0].email)
                        console.log(isEmailValid)
                        if(isEmailValid){
                            alert("Correct Email");
                            savePassword(pass,username)     
                        }
                        else{
                            alert("Incorrect Email");
                        }
                    }
                })
        }else{
            alert("The Passwords you typed don't match");
        }

    }

    const savePassword = async (pass, username) => {
        const result = {
            password: pass,
            username: username
        }
        await axios.put(`http://${serverIP}:3002/api/updatePassword`, result)
            .then(response => {
                console.log(response)
                alert("Password Changed")
            }).catch(error => {
                console.log(error)
            })
    }
    //#endregion


    //#region Use Effect
    useEffect(()=> {

        const fetchCsrfToken = async () => {
            try {
                await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
                });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
                console.log('Tokens set');
            })
            .catch(error => {
                console.error('Error setting tokens:', error);
            });
        };

        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };
    },[]) 
    //#endregion   



    return(
        <IonPage >
            <IonHeader className='form_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent fullscreen>
                <div className='form_background'></div>
                <div className='form_background_img'></div>
                <div className="form_main">
                    <div className="form">
                            <img src={logo} className="form_logo"></img>
                            <IonInput className="form_input" ref ={PassRef} label="New Password" type="password" labelPlacement="floating" fill="outline" placeholder="Enter New Password"></IonInput>
                            <IonInput className="form_input" ref ={ConPassRef} label="Confirm Password" type="password" labelPlacement="floating" fill="outline" placeholder="Re-enter Password"></IonInput>
                            <IonButton color='secondary' className="form_btn" onClick = {() => ChangePassword(PassRef.current.value, ConPassRef.current.value, email, username)}>Change Password</IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )

    }



export default ChangePass;