//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
//Import Style
import './Privacy.css';
//#endregion


const Privacy = () => {
    
return(
    <IonPage>
      <IonHeader class='privacy_header'>
          <NavBar></NavBar>
      </IonHeader>
      <IonContent className="privacy_content">
      <div className='privacy_background'></div>
        <div className='privacy_background_img'></div>
        <div className="story">
            <h2 className="privacyTitle">Privacy Policy</h2> 
            <h4 className="privacy_title">1. Introduction:</h4>
            <p> We at Recibits are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website Recibits.co.</p>
            <h4 className="privacy_title">2. Data Collection and Usage</h4>
            <p>We collect personal data that you voluntarily provide to us when you create an account, make a purchase, or contact us. The personal data we collect includes:</p>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                </ul>
                <p>Purposes of Data Collection:</p>	
                <ul>
                    <li>To create and manage your account</li>
                    <li>For email marketing purposes</li>
                </ul>
                <p>Cookies: We use cookies to save personal IDs to retrieve account favorites and premium information.</p>
            <h4 className="privacy_title">3. Data Sharing and Security</h4>
            <ul>
                <li>Personal data is saved in our database and never shared with third parties.</li>
                <li>Data is safely saved and encrypted in the database to protect against any breach of accounts.</li>
            </ul>
            <h4 className="privacy_title"> 4. User Rights </h4> 
            <p>Users have the ability to change their data and request the deletion of their accounts. Users might be contacted via email for marketing purposes.</p>
            <p>Under GDPR:</p>
            <ul>
                <li>Right to Access: You have the right to request access to your personal data and obtain a copy of it.</li>
                <li>Right to Rectification: You have the right to request correction of inaccurate or incomplete personal data.</li>
                <li>Right to Erasure: You have the right to request the deletion of your personal data under certain conditions.</li>
                <li>Right to Restrict Processing: You have the right to request the restriction of processing of your personal data under certain conditions.</li>
                <li>Right to Data Portability: You have the right to request the transfer of your personal data to another organization or directly to you under certain conditions.</li>
                <li>Right to Object: You have the right to object to the processing of your personal data under certain conditions.</li>
            </ul>
            <p>Under CCPA:</p>
            <ul>
                <li>Right to Know: You have the right to know the categories of personal data we collect, the purposes for which we use it, and the categories of third parties with whom we share it.</li>
                <li>Right to Access: You have the right to request access to the specific pieces of personal data we have collected about you.</li>
                <li>Right to Deletion: You have the right to request the deletion of your personal data, subject to certain exceptions.</li>
                <li>Right to Opt-Out: You have the right to opt-out of the sale of your personal data. (Note: We do not sell personal data.)</li>
                <li>Right to Non-Discrimination: You have the right not to be discriminated against for exercising your CCPA rights.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at recibits@gmail.com.</p>
            <h4 className="privacy_title">5. Changes to This Privacy Policy</h4>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            <h4 className="privacy_title">6. Contact Us</h4>
            <p>If you have any questions about this Privacy Policy, please contact us at recibits@gmail.com.</p>
        </div>
            
            <Footer></Footer>

            </IonContent>
   
      </IonPage>
)

}

export default Privacy;