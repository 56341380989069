import { Component } from "react";


class Recipe extends Component{

    constructor(Id, rid, name, title, subTitle, description, steps, images, category, isVisible, ingredients, time, portion, origin, difficulty) {

        super()
      
        this.state = {
             id: Id,
             rid: rid,
             name: name,
             title: title,
             subTitle: subTitle,
             description: description,
             steps: steps,
             images: images,
             category: category,
             isVisible: isVisible,
             ingredients: ingredients,
             time: time,
             portion: portion,
             origin: origin,
             difficulty: difficulty,
             
        }
    }

    //#region Getters
    getId(){
        return this.state.id;
    }
    getrId(){
        return this.state.rid;
    }
    getName(){
        return this.state.name;
    }
    getTitle(){
        return this.state.title;
    }
    getSubTitle(){
        return this.state.subTitle;
    }
    getDescription(){
        return this.state.description;
    }
    getSteps(){
        return this.state.steps;
    }
    getImages(){
        return this.state.images;
    }
    getCategory(){
        return this.state.category;
    }
    getIsVisible(){
        return this.state.isVisible;
    }
    getIngredients(){
        return this.state.ingredients;
    }
    getTime(){
        return this.state.time;
    }
    getPortion(){
        return this.state.portion;
    }
    getOrigin(){
        return this.state.origin;
    }
    getDifficulty(){
        return this.state.difficulty;
    }
    //#endregion




    render() {
        return(
            <p>{this.state.name}</p>
        )
    }

}

export default Recipe