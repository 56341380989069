import React, {useRef, useState} from 'react';
import { IonInput, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonButton, IonTextarea} from '@ionic/react';
import axios from 'axios';
import "./Opinion.css"

function Opinion() {

    const serverIP = "localhost"
    const userRef = useRef("");
    const [user, setUser] = useState("")
    const emailRef = useRef("");
    const [email, setEmail] = useState("")
    const opRef = useRef("");
    const [op, setOp] = useState("")

    const handleSubmit = async (username, email, opinion) => {
        setOp("")
        setUser("")
        setEmail("")
        if(username !== ""){
            if(opinion !== ""){
                const result = {
                    email: email,
                    username: username,
                    opinion: opinion
                }
                await axios.post(`http://${serverIP}:3002/api/InsertOpinion`, result)  
                    .then(response => {
                        console.log(response);
                      })
                      .catch(error => {
                        console.log(error);
                      })
            }else{
                alert("Please Fill in the field with your Opinion")
            }
            
        }else{
            alert("Please Fill the username Field")
        }
        
    }
  
    return (

        <IonGrid className="form_container">
        <IonRow className="input_field">
            <IonCol size="12">
            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput
                value={user}
                ref={userRef}
                onIonChange={e => setUser(e.detail.value)}
                placeholder="Enter your full name"
                type="text"
              />
            </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="input_field">  
            <IonCol size="12">
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                value={email}
                ref={emailRef}
                onIonChange={e => setEmail(e.detail.value)}
                placeholder="Enter your email"
                type="email"
              />
            </IonItem>
            </IonCol>
           </IonRow>
           <IonRow className="textarea_field">
            <IonCol size="12"> 
            <IonItem>
              <IonLabel position="stacked">Opinion</IonLabel>
              <IonTextarea
                autoGrow={true}
                value={op}
                ref={opRef}
                onIonChange={e => setOp(e.detail.value)}
                placeholder="Enter your opinion"
              />
            </IonItem>
            </IonCol>
          <IonCol size="12">
            <IonButton color="secondary" expand="block" onClick={() => handleSubmit(userRef.current.value, emailRef.current.value, opRef.current.value)} className="submit_button">Submit</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

export default Opinion