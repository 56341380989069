//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage, useIonAlert } from "@ionic/react"

//Style
import './form.css'

//Axios
import axios from 'axios'

//Use React
import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

//Resources
import logo from '../resources/logo/logo_transparent_edited.png'

//Components
import NavBar from "../components/NavBar";
//#endregion



const Login = () => {



    //#region Variables
    //Routes
    const history = useHistory();
    //Server IP Address
    //References
    const serverIP = "localhost"
    const userRef = useRef(null)
    const passRef = useRef(null)
    //Alert
    const [loginAlert] = useIonAlert();
    //#endregion


    //#region Use Effect
    useEffect(()=>{
        
        const fetchCsrfToken = async () => {
            try {
              await axios.get(`http://${serverIP}:3002/api/csrf-token`,{withCredentials: true});
            } catch (error) {
              console.error('Error fetching CSRF token:', error);
            }
          };


        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`http://${serverIP}:3002/api/refresh-authenticate`,{},{withCredentials: true })
              .then(response => {
                // console.log('Access token refreshed');
              })
              .catch(error => {
                // console.error('Error refreshing token:', error);
              });
          }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
          
      
          const authenticateAPI = async () => {  
            await axios.post(`http://${serverIP}:3002/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
              console.log('Tokens set');
            })
            .catch(error => {
              console.error('Error setting tokens:', error);
            });
          }


         //Check cookies for log in info and save the id
        const getProtectedResource = async () => {
            try {
            const response = await axios.get(`http://${serverIP}:3002/api/protected`, { withCredentials: true });
                history.replace({
                    pathname: '/Home'
                })
            } catch (error) {
            if (error.response && error.response.status === 401) { // Auth Cookie not found
                try {
                    await axios.post(`http://${serverIP}:3002/refresh`, {}, { withCredentials: true });
                    const retry = await axios.get(`http://${serverIP}:3002/api/protected`, { withCredentials: true });
                    history.replace({
                        pathname: '/Home'
                    })
                } catch (refreshError) { // No refresh cookie found
                    return
                }
            }
            }
        };

        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
            getProtectedResource()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };


    },[])
    //#endregion


    //#region Events
    //Login
    const login = async (username, password) => {
        try{
        const re = await axios.post(`http://${serverIP}:3002/api/login`, {username, password}, { withCredentials: true })
                .then(async (result) => {
                    if(result.data.length == 0){
                        loginAlert({
                            header: 'Alert',
                            message: 'Unexpected error Occured!',
                            buttons: ['Ok'],
                          })
                          return
                    }
                    else{                      
                        const dataSend = {
                            username: username,
                            id: result.data[0].id,
                            issubscribed: result.data[0].issubscribed
                        }
                        history.replace({
                            pathname: '/Home',
                            state: {data: dataSend}
                        })
                    }       
                })
            }catch(err){
                console.log(err)
                if(err && err.response && err.response.status === 401){
                    loginAlert({
                        header: 'Alert',
                        message: 'Incorrect Credentials',
                        buttons: ['Ok'],
                      })
                }
            }                    
    }
    //#endregion

    


    return(
        <IonPage >
            <IonHeader className='form_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent fullscreen>

            <div className='form_background'></div>
            <div className='form_background_img'></div>
            

                <div className="form_main">
                    <div className="form">

                            <img src={logo} className="form_logo"></img>

                            <IonInput ref={userRef} className="form_input" label="Username" labelPlacement="floating" fill="outline" placeholder="Enter username"></IonInput>
                            <IonInput ref={passRef} className="form_input" label="Password" type="password" labelPlacement="floating" fill="outline" placeholder="Enter password"></IonInput>
                            
                            <IonButton color="secondary" className="form_btn" onClick={() => login(userRef.current.value, passRef.current.value)}>Login</IonButton>

                            <div className="form_tags">
                            <a className="form_tag" href="http://localhost/signup">Create an Account</a>
                            <a href="http://localhost/home/forgetPass">Forgot Password</a>
                            </div>
                    </div>



                </div>
            </IonContent>
        </IonPage>
    )
    }



export default Login;