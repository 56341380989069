//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonPage, IonSearchbar, IonGrid, IonCol, IonRow, IonImg, IonButton, useIonAlert, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';

//Style
import './Home.css';

//Axios
import axios from 'axios'

//Use React
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

//Resources
import one from "../resources/PanelOneMid.png"
import crown from "../resources/crown.png"

//Classes
import Recipe from '../components/Recipe';

//Components
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import RecipeCard from '../components/RecipeCard';
import PremiumReciCard from '../components/premiumRecipeCard'

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

//Swiper styles
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import "swiper/css";
import "swiper/css/navigation";

//Function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();
//#endregion



const Home = () => {



  //#region Variables
  //States
  const [isLoading, setLoading] = useState(true)
  const [recipeList, setRecipeList] = useState([])
  const [allCategories, setAllCategories] = useState(true);
  const [categories,setCategories] = useState([])
  const [usedFilter, setFilter] = useState(0)
  const [recentRecipes, setRecentRecipes] = useState([])
  const [searchData, setSearchData] = useState('');
  const [isLoggedin, setisLoggedin] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [favouriteList, setFav] = useState([]);
  const [favPressed, setfavPressed] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(undefined)
  const [personId, setPersonId] = useState(null)
  const [isPremium, setUserPremium] = useState(false)
  const [listLoading, setListLoading] = useState(false)

  const serverIP = "localhost"
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [error, setError] = useState(null)
  //Routes
  const location = useLocation();
  const receivedData = location.state && location.state.data;
  const history = useHistory();
  //Alert
  const [presentAlert] = useIonAlert();

  //#endregion

  //#region Functions
    //Login
    const onLoginClick = () => {
      history.replace({
        pathname: '/login'
      })
    }

    //Subscribe
    const onSubscribeClick = () => {
        const dataSend = {
          isLoggedin: isLoggedin,
          id:personId
        }
        history.replace({
          pathname: '/premium',
          state: {data: dataSend}
        })
    }


    //Logout
    const onLogout = async () => {
      await axios.post(`http://${serverIP}:3002/api/logout`, {}, { withCredentials: true });
      setisLoggedin(false)
      setShowAccount(false)
      setfavPressed(false)
      setFav([])
      setPersonId(null)
      setUserPremium(false)
      history.replace({
        pathname: '/'
      })
    }

    //Choose Recipe
    const onClickRecipe = (id) => {
        if(!favPressed){
          history.push({
              pathname: `/recipe/${recipeList[id].getrId()}/${encodeURIComponent(recipeList[id].getTitle())}`
          });
        }else{
          history.push({
            pathname: `/recipe/${favouriteList[id].getrId()}/${encodeURIComponent(favouriteList[id].getTitle())}`
        });
        }
    }

    //Choose Recent Recipe
    const onClickRecentRecipe = (id) => {
      if(isPremium){
      history.push({
        pathname: `/recipe/${recentRecipes[id].getrId()}/${encodeURIComponent(recentRecipes[id].getName())}`
      });
      }else{
        presentAlert({
          header: 'Alert',
          message: 'You have to be subscribed to check Recent Recipes',
          buttons: ['Ok'],
        })
      }
    }

    //Filter Recipes
    const filter = (id) => {

      setListLoading(true)

      setTimeout(()=>{
        if(id == ""){
          setAllCategories(true)
          setFilter(0)
          // return;
        }else{
          setAllCategories(false)
          setFilter(id)
        }
        
        setListLoading(false)
      }, 500)

      
      
    }

    //Search 
    const setSearch = (e) => {
      setSearchData(e.detail.value)
    }

    //Favourite Only
    const setFavourite = () => {
      if(favPressed){
        setfavPressed(false)
      }else{
        setfavPressed(true)
      }
    }

  //#endregion

  



  //#region Use Effect

  useEffect(() => {


    const fetchCsrfToken = async () => {
      try {
        await axios.get(`http://${serverIP}:3002/api/csrf-token`,{withCredentials: true});
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };


    const intervalId = setInterval(() => {
      // Call the refresh token endpoint to renew the access token
      axios.post(`http://${serverIP}:3002/api/refresh-authenticate`,{},{withCredentials: true })
        .then(response => {
          // console.log('Access token refreshed');
        })
        .catch(error => {
          // console.error('Error refreshing token:', error);
        });
    }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
    

    const authenticateAPI = async () => {  
      await axios.post(`http://${serverIP}:3002/api/authenticate`,{}, { withCredentials: true})
      .then(response => {
        console.log('Tokens set');
      })
      .catch(error => {
        console.error('Error setting tokens:', error);
      });
    }

    //Check cookies for log in info and save the id
    const getProtectedResource = async () => {
      try {
        const response = await axios.get(`http://${serverIP}:3002/api/protected`,{ withCredentials: true });
        setisLoggedin(true)
        setShowAccount(true)
        setPersonId(response.data)
        getFavourite(response.data)
        checkPremium(response.data)
      } catch (error) {
        if (error.response && error.response.status === 401) { // Auth Cookie not found
          try {
            await axios.post(`http://${serverIP}:3002/api/refresh`, {}, { withCredentials: true  });
            const retry = await axios.get(`http://${serverIP}:3002/api/protected`,{ withCredentials: true });
            setisLoggedin(true)
            setShowAccount(true)
            setPersonId(retry.data)
            getFavourite(retry.data)
            checkPremium(retry.data)
          } catch (refreshError) { // No refresh cookie found
            console.log(refreshError)
            
          }
        }
      }
    };
    

    //Gets Favourite of current user if he is Logged in
    const getFavourite = async (id) => {
      var tempFavList= [];
      await axios.get(`http://${serverIP}:3002/api/favourite?id=${encodeURIComponent(id)}`, { withCredentials: true })
                  .then((result) => {
                      if(result.data == 'no data'){
                          return
                      }
                      var count = 0;
                      result.data.forEach(dt => {
                        tempFavList.push(new Recipe(count,dt.recipe_id,dt.recipe_name,dt.recipe_title,dt.recipe_subtitle,dt.recipe_description,dt.recipe_steps,dt.recipe_images,dt.recipe_categories,dt.recipe_visible,dt.recipe_ingredients,dt.recipe_time,dt.recipe_portion,dt.recipe_country,dt.recipe_difficulty))
                        count++;
                      }) 
                  }).finally(()=>{
                      setFav(tempFavList)
                  }).catch((err) => {
                      setError(err)
                  });              
    }


    // Get Categories for the filtering
    const getCategories = async () => {
      var categoryList = [];
      await axios.get(`http://${serverIP}:3002/api/categories`, { withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    result.data.forEach(dt => { 
                        categoryList.push({id: dt.id, name: dt.category});
                    }) 
                }).finally(() => {
                    setCategories(categoryList)
                }).catch((err) => {
                    setError(err)
                })    
                            
    }
  

    //Get The Recent Recipes Only for Premium
    const getRecentRecipes = async () => {
      
      var tempRecentList = [];
      await axios.get(`http://${serverIP}:3002/api/recent`, { withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    var count = 0;
                    result.data.forEach(dt => {
                      tempRecentList.push(new Recipe(count,dt.id,dt.name,dt.title,dt.subtitle,dt.description,dt.steps,dt.images,dt.Categories,dt.isVisible,dt.ingredients,dt.time,dt.portion,dt.origin,dt.difficulty))
                      count++;
                    }) 
                }).finally(()=>{
                    setRecentRecipes(tempRecentList)
                }).catch((err) => {
                    setError(err)
                });
    }


    //Get All Visible Recipes
    const getRecipes = async () => {
      var tempRecipeList = [];
      await axios.get(`http://${serverIP}:3002/api`, { withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    var count = 0;
                    result.data.forEach(dt => { 
                      tempRecipeList.push(new Recipe(count,dt.id,'',dt.title,'','',[],dt.images,dt.category,true,[],dt.time,dt.portion,'',dt.difficulty))
                      count++;
                    }) 
                }).finally(()=>{
                    setRecipeList(tempRecipeList)
                    setLoading(false)
                }).catch((err) => {
                    setError(err)
                });
    }


    //Check if the logged in user is subscribed
    const checkPremium = async (id) => {
      console.log(id)
      await axios.get(`http://${serverIP}:3002/api/checkPremium?id=${encodeURIComponent(id)}`, { withCredentials: true })
                .then((result) => {
                    if(result.data == 'no data'){
                        return
                    }
                    if(result.data)
                      setUserPremium(true)
                }).catch((err) => {
                    setError(err)
                });
    }



    const loadData = async () => {
      //Initializing
      await fetchCsrfToken()
      await authenticateAPI()
      await getProtectedResource() //If logged in + favourite + subscribed?;
      getRecentRecipes() //Recent premium recipes
      getCategories() //Categories of the recipes
      getRecipes() //All recipes   
    }

    
    loadData()

    //Handle the resizing of the screen in real time
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    //removes listener when unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId); 
    };

  
  }, [location]);
  

  //#endregion



  
  return (
    <IonPage className="Page" >

      {/* Header */}
      <IonHeader className='home_header'>
          <NavBar onLoginClick={onLoginClick} onSubscribeClick={onSubscribeClick}  showAccount={showAccount} onLogout={onLogout} showLogin={!isLoggedin}></NavBar>
      </IonHeader>
      <div className='home_background'></div>
      

      {/* Content */}
      <IonContent  className='home_content' scrollEvents={true} forceOverscroll={false}>

        {/* Background */}
        
        <div className='home_background_img'></div>


        {/* Swiper */}
        <Swiper
          className="home_slider"
          slides-per-view="1"
          grid-rows="1"
          mousewheel-force-to-axis="true"
          loop={true}
          navigation={true}
          module={[Navigation, Pagination]}
          pagination={{clickable: true}}
        >
          <SwiperSlide className='slide'>
            <img className='slideImg' src={one} alt='one'></img>
          </SwiperSlide>
          <SwiperSlide className='slide'> 
           <IonImg src={one} alt='two' className='slideImg'></IonImg>
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <IonImg src={one} alt='three' className='slideImg'></IonImg>
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <IonImg src={one} alt='four' className='slideImg'></IonImg>
          </SwiperSlide>
        </Swiper>



        {/* Recent Recipes */}
        { !isLoading && recentRecipes.length>0 ? (
          <>
            <div className='recent_title'>
              <div className='recent_title_title' style={{}}>Premium</div>
            </div>

            <div className='recent'>
              <div className='recent_bg'></div>
              <div className='recent_container'>       
                <img src={crown}/>
                <img src={crown}/>
                <img src={crown}/>
                <img src={crown}/>
              </div>
              <div className='recent_recipes'> 
                <IonGrid className="lister_grid">
                  <IonRow className="lister_row" size="4">
                    {
                      recentRecipes.map((recipe,i)=>{return(
                        <IonCol className='lister_col' sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                          <PremiumReciCard recipe={recipe} onClickRecipe={onClickRecentRecipe}/>
                        </IonCol> 
                      )})
                    }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </>
        ): null }   
       


       
        {/* Category Search and Favourite */}
        { !isLoading ? ( 
          <div className='categories_div'>
                <IonSelect className={selectedCategory==0 || selectedCategory==undefined ? 'home_category_select' : 'home_category_selected'} value={selectedCategory} interface="popover" placeholder="Category" onIonChange={e => {(setSelectedCategory(e.detail.value));filter(e.detail.value)}}>
                  <IonSelectOption className='home_category_item' value="" key={0}>None</IonSelectOption>
                  {categories.map((category)=>{return(
                    <IonSelectOption className='home_category_item'  value={category.id} key={category.id}>{category.name}</IonSelectOption>
                  )})}       
                </IonSelect>
                <IonSearchbar value={searchData} onIonInput={setSearch} showClearButton='focus'className={searchData=='' ? 'search' : 'searched'} ></IonSearchbar>
                { isLoggedin ? 
                <IonButton className={favPressed ? 'home_fav_btn_clicked' : 'home_fav_btn'} onClick={setFavourite}>Favourite</IonButton>
                :null
                }       
          </div>
        ): null }



        
        {windowWidth > 700 ? (


          // WINDOWS LANDSCAPE
          <div className='home_recipe_body'>

            {/* ADS */}
            <div className='home_ads'>
              <div className='ad_item'>&nbsp; </div>
              <div className='ad_item'>&nbsp; </div>
            </div>

            {/* Recipe List */}
            { !isLoading && !listLoading ? (
              
              // Recipe List If Favorite is clicked (includes search and category)
              favPressed ? (
                <IonGrid className="lister_grid">
                  <IonRow className="lister_row" size="4">
                    {(function() {
                      const filteredRecipes = allCategories 
                        ? favouriteList.filter(recipe => recipe.getTitle().toLowerCase().includes(searchData.toLowerCase())) 
                        : favouriteList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()));
                      return filteredRecipes.length > 0 ? (
                        filteredRecipes.map((recipe, i) => (
                        <IonCol className="lister_col" sizeXs="12" sizeSm="6" sizeMd='6' sizeLg='4' key={i}>
                          <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe}/>
                        </IonCol>
                      ))
                      ) : (
                        <div className='home_no_recipe'>No results found</div>
                      );
                    })()
                    }
                  </IonRow>
                </IonGrid>
              ):


              // Recipe List Without Favorite (includes search and category)
              <IonGrid className="lister_grid">
                <IonRow className="lister_row" size="4">
                  {
                    (function() {
                      const filteredRecipes = allCategories 
                        ? recipeList.filter(recipe => recipe.getTitle().toLowerCase().includes(searchData.toLowerCase())) 
                        : recipeList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()));
                      return filteredRecipes.length > 0 ? (
                        filteredRecipes.map((recipe, i) => (
                          <IonCol className="lister_col" sizeXs="12" sizeSm="6" sizeMd='6' sizeLg='4' key={i}>
                            <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe}/>
                          </IonCol>
                        ))
                      ) : (
                        <div className='home_no_recipe'>No results found</div>
                      );
                    })()
                  }
                </IonRow>
              </IonGrid>


            ) : (
            
              <div className='loading'>
                Loading
              <IonSpinner name='circular' className='spinner'>  </IonSpinner>
            </div>
            )
            }

            {/* ADS */}
            <div className='home_ads'>
              <div className='ad_item'>&nbsp; </div>
              <div className='ad_item'>&nbsp; </div>
            </div>

          </div>

        ):


        // WINDOWS PORTRAIT


        <div className='home_recipe_body'>

          {/* ADS */}
          {/* <div className='home_ads_mobile'>
            <div className='ad_item'>&nbsp; </div>
          </div> */}

          {/* Recipe List */}
          { !isLoading && !listLoading ? (
        
          // Recipe List If Favorite is clicked (includes search and category)
          favPressed ? (
            <IonGrid className="lister_grid">
              <IonRow className="lister_row" size="6">
                {(function() {
                  const filteredRecipes = allCategories 
                    ? favouriteList.filter(recipe => recipe.getName().toLowerCase().includes(searchData.toLowerCase())) 
                    : favouriteList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getName().toLowerCase().includes(searchData.toLowerCase()));
                  return filteredRecipes.length > 0 ? (
                    filteredRecipes.map((recipe, i) => (
                    <IonCol className="lister_col" sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                      <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe}/>
                    </IonCol>
                  ))
                  ) : (
                    <div className='home_no_recipe'>No results found</div>
                  );
                })()
                }
              </IonRow>
            </IonGrid>
          ):


          // Recipe List Without Favorite (includes search and category)
          <IonGrid className="lister_grid">
            <IonRow className="lister_row" size="6">
              {
                (function() {
                  const filteredRecipes = allCategories 
                    ? recipeList.filter(recipe => recipe.getName().toLowerCase().includes(searchData.toLowerCase())) 
                    : recipeList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getName().toLowerCase().includes(searchData.toLowerCase()));
                  return filteredRecipes.length > 0 ? (
                    filteredRecipes.map((recipe, i) => (
                      <IonCol className="lister_col" sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                        <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe}/>
                      </IonCol>
                    ))
                  ) : (
                    <div className='home_no_recipe'>No results found</div>
                  );
                })()
              }
            </IonRow>
          </IonGrid>


          ) : 

          <div className='loading'>
            <IonSpinner name='circular' className='spinner'></IonSpinner>
          </div>
          
          }



        </div>



}
            <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
