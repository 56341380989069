//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonItem, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
//Import Style
import './Privacy.css';
//#endregion


const Terms = () => {
return(
    <IonPage>
      <IonHeader class='privacy_header'>
          <NavBar></NavBar>
      </IonHeader>
      <IonContent className="privacy_content">
      <div className='privacy_background'></div>
        <div className='privacy_background_img'></div>
        <div className="story">
            <h2 className="privacyTitle">Terms and Conditions</h2> 
            <h4 className="privacy_title">1. Introduction:</h4>
            <p> Welcome to Recibits. These Terms and Conditions govern your use of our website Recibits.co and services. By accessing or using our website, you agree to be bound by these Terms.</p>
            <h4 className="privacy_title">2. Eligibility</h4>
                <ul>
                    <li>Users must be at least 13 years old to create an account.</li>
                    <li>By creating an account, users represent and warrant that they meet the eligibility requirements.</li>
                </ul>
            <h4 className="privacy_title">3. Account Information</h4>
            <ul>
                <li>Users must provide accurate and complete information during the registration process.</li>
                <li>Users are responsible for updating their account information to keep it current and accurate.</li>
            </ul>
            <h4 className="privacy_title"> 4. Account Security </h4> 
            <ul>
                <li>Users are responsible for maintaining the confidentiality of their account credentials, including their password.</li>
                <li>Users must notify us immediately of any unauthorized use of their account or any other breach of security.</li>
                <li>Users must use a strong password that includes a combination of letters, numbers, and special characters.</li>
            </ul>
            <h4 className="privacy_title"> 5. Account Usage </h4> 
            <ul>
                <li>Users must not share their account with others or allow others to access their account.</li>
                <li>Users are solely responsible for all activities that occur under their account.</li>
                <li>Users must not use their account for any illegal or unauthorized purpose.</li>
            </ul>
            <h4 className="privacy_title"> 6. Account Termination </h4> 
            <ul>
                <li>We reserve the right to suspend or terminate accounts that violate these Terms and Conditions, are inactive for an extended period, or are found to be involved in fraudulent or illegal activities.</li>
                <li>Users may terminate their account at any time by contacting us.</li>
            </ul>
            <h4 className="privacy_title"> 7. Multiple Accounts</h4> 
            <p>Users are prohibited from creating multiple accounts for the purpose of circumventing account limitations or engaging in deceptive practices.</p>
            <h4 className="privacy_title"> 8. Prohibited Activities</h4>
            <ul>
                <li>Users must not engage in activities that harm or disrupt the website or service, including but not limited to hacking, distributing malware, or spamming.</li>
                <li>Users must respect the intellectual property rights of others and must not upload, post, or distribute content that infringes on these rights.</li>
            </ul>
            <h4 className="privacy_title"> 9. Notifications and Communication</h4>
            <ul>
                <li>By creating an account, users agree to receive communications from us, including but not limited to account-related notifications, updates, and promotional messages.</li>
                <li>Users can manage their communication preferences through their account settings or by contacting us.</li>
            </ul>
            <h4 className="privacy_title"> 10. Compliance with Laws</h4>
            <p>Users must comply with all applicable laws and regulations when using their account and accessing our services.</p>
            <h4 className="privacy_title"> 11. User Contributions</h4>
            <ul>
                <li>Users should not violate any guidelines when sending opinions or feedback.</li>
                <li>Users must not use any content on the website for commercial purposes without permission.</li>
            </ul>
            <h4 className="privacy_title"> 12. Dispute Resolution</h4>
            <ul>
                <li>Governing Law: These Terms and Conditions and any disputes arising out of or relating to them will be governed by and construed in accordance with the laws of Lebanon, without regard to its conflict of law principles.</li>
                <li>Jurisdiction: You agree that any legal action or proceeding arising out of or relating to these Terms and Conditions or your use of our website/service shall be brought exclusively in the courts of Lebanon. You consent to the jurisdiction of and venue in such courts and waive any objection to such jurisdiction or venue.</li>
                <li>Informal Resolution: Before initiating arbitration, you agree to attempt to resolve any dispute informally by contacting us at recibits@gmail.com. We will attempt to resolve the dispute informally by contacting you through email or other means. If a dispute is not resolved within 30 days of submission, either party may begin arbitration proceedings.</li>
            </ul>
            <h4 className="privacy_title"> 13. Compliance with GDPR and CCPA</h4>
            <ul>
                <li>GDPR Compliance: If you are a resident of the European Economic Area (EEA), you have certain data protection rights under the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and ensuring its fair and transparent processing. To exercise any of these rights, please contact us at recibits@gmail.com.</li>
                <li>CCPA Compliance: If you are a resident of California, you have certain rights under the California Consumer Privacy Act (CCPA). We are committed to protecting your personal data and ensuring its fair and transparent processing. To exercise any of these rights, please contact us at recibits@gmail.com.</li>
            </ul>
            <h4 className="privacy_title">14. Changes to These Terms</h4>
            <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes.</p>
            <h4 className="privacy_title">15. Contact US</h4>
            <p>If you have any questions about these Terms, please contact us at recibits@gmail.com.</p>
        </div>
            
            <Footer></Footer>

            </IonContent>
   
      </IonPage>
)

}

export default Terms;