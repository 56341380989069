//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonItem, IonButton, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
//Import Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//Import Axios
import axios, { all } from 'axios'
//Import React Components
import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
//Import Style
import './form.css'
//#endregion

const SignUp = () => {

    //#region Variables
    const history = useHistory();
    const serverIP = "recibits.co"
    const userRef = useRef(null)
    const passRef = useRef(null)
    const emailRef = useRef(null)
    //#endregion

    //#region Functions
    const checkUsername = async (username, password, email) => {
        let shouldContinue = false
        await axios.get(`https://${serverIP}/api/checkUs?username=${encodeURIComponent(username)}`,{withCredentials: true })
                .then((result) => {
                    console.log(result.data.length)
                    if(result.data.length == 0){
                        console.log("Continue")
                        shouldContinue = true
                    }
                    else {
                        alert('Username Already Exists');
                        console.log("bad");
                        return
                    }
                        
        })
        if(shouldContinue){
            shouldContinue = false
            await axios.get(`https://${serverIP}/api/checkEmail?email=${encodeURIComponent(email)}`,{withCredentials: true })
                .then((result) => {
                    console.log(result)
                    if(result.data.length == 0){
                        console.log("Continue2")
                        shouldContinue = true
                    }
                    else{
                        alert('Email Already Exists');
                        console.log("bad");
                        return
                    }     
        }) }
        if(shouldContinue){
            const result = {
                email: email,
                username: username,
                password: password
            }
            await axios.post(`https://${serverIP}/api/InsertData`, result,{withCredentials: true })  
            .then(response => {
                console.log(response);
              })
              .catch(error => {
                console.log(error);
              })
            }
            
    }
    //#endregion
    

    //#region Use Effect
    useEffect(()=>{
    

        const fetchCsrfToken = async () => {
            try {
                await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
            };



        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
                });
            }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        
            const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
                console.log('Tokens set');
            })
            .catch(error => {
                console.error('Error setting tokens:', error);
            });
            }


            //Check cookies for log in info and save the id
        const getProtectedResource = async () => {
            try {
            const response = await axios.get(`https://${serverIP}/api/protected`, { withCredentials: true });
                history.replace({
                    pathname: '/Home'
                })
            } catch (error) {
            if (error.response && error.response.status === 401) { // Auth Cookie not found
                try {
                    await axios.post(`https://${serverIP}/refresh`, {}, { withCredentials: true });
                    const retry = await axios.get(`https://${serverIP}/api/protected`, { withCredentials: true });
                    history.replace({
                        pathname: '/Home'
                    })
                } catch (refreshError) { // No refresh cookie found
                    return
                }
            }
            }
        };

        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
            getProtectedResource()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };


    },[])
    //#endregion


    return(
        <IonPage>
            <IonHeader className='form_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent>
                <div className="form_main">


                <div className='form_background'></div>
            <div className='form_background_img'></div>


                    <div className="form">
                            <img src={logo} className="form_logo"></img>
                            <IonInput ref={userRef} className="form_input" label="Username" labelPlacement="floating" fill="outline" placeholder="Enter username" required></IonInput>
                            <IonInput ref={emailRef} className="form_input" label="Email" labelPlacement="floating" fill="outline" placeholder="Enter email" required></IonInput>
                            <IonInput ref={passRef} className="form_input" label="Password" type="password" labelPlacement="floating" fill="outline" placeholder="Enter password" required></IonInput>
                            
                            <IonButton className="form_btn signup_btn" color="secondary" onClick={() => checkUsername(userRef.current.value, passRef.current.value, emailRef.current.value)}>SignUp</IonButton>
                        
                            <div className="form_tags signup_tags">
                                <a className="form_tag signup_tag" href = "https://recibits.co:8100/Login">Already have an account?</a> 
                            </div>
                        {/* </IonItem> */}
                    </div>

                </div>
            </IonContent>

            SignUp
        </IonPage>
    )}



export default SignUp;