//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
//Import Style
import './AboutUs.css';
//#endregion

const AboutUs = () => {

return(

    <IonPage>
        <IonHeader class='aboutus_header'>
            <NavBar></NavBar>
        </IonHeader>

        <IonContent fullscreen>
            <div className='aboutus_background'></div>
            <div className='aboutus_background_img'></div>
            <div className="aboutus">
                <h2 className="aboutus_mainTitle">About Recibits</h2> <br></br> <br></br>
                <div className="aboutus_body_div">
                    <h1 className="aboutus_titles">Our Story</h1>
                    <h5>Living abroad and away from family, we found ourselves relying on fast food and unhealthy options. However, we soon realized that this lifestyle wasn't sustainable or satisfying.</h5>
                    <h5>Determined to make a change, we started the journey of cooking worldwide meals from scratch. It was a journey of ups and downs, laughter and frustrations, but most importantly, it was an adventure that brought us closer together and ignited a passion for cooking.</h5>
                    <h5>As we honed our skills and discovered the joy of creating meals, we decided to share the experience with everyone. And so, Recibits was born - a place where we could document our adventures, share our favorite recipes, and inspire others to start their there own cooking journey.</h5><br></br>
                    <h3 className="aboutus_titles">Our Mission</h3>
                    <h5>We aim to empower home cooks of all skill levels to improve their creativity and discover the joy of cooking. We strive to create a diverse collection of recipes, cooking tips and techniques that inspire our community.</h5><br></br>
                    <h3 className="aboutus_titles">What Sets Us Apart</h3>
                    <h5>What makes Recibits different is our commitment to making cooking accessible and enjoyable for everyone. Whether you are professional chef or a kitchen novice, our recipes are designed to be approachable and practical.</h5><br></br>
                    <h3 className="aboutus_titles">Join Our Community</h3>
                    <h5>We invite you to join our growing community of food enthusiasts! Connect with us on social media and drop us your opinion. We'd love to hear from you!</h5><br></br>
                </div>
            </div>
            <Footer></Footer>
        </IonContent>
    </IonPage>
)

}

export default AboutUs;