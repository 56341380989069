//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonItem, IonButton, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
//Import Axios
import axios from 'axios'
//Import React Components
import { useEffect, useRef} from 'react';
import bcrypt from "bcryptjs-react";
//Import Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//Import Style
import './form.css'
//#endregion


const ResetPass = () => {

    //#region Variables
    const serverIP = "localhost";
    const username = "Omar22"
    const oldPass = useRef(null); 
    const PassRef = useRef(null);
    const ConPassRef = useRef(null);
    //#endregion

    //#region Functions
    const ResetPassword = async (pass, cPass, oldpass, username) => {

        if(pass == cPass){


            await axios.get(`http://${serverIP}:3002/api/credentials?username=${encodeURIComponent(username)}`)
                    .then(async (result) => {
                        if(result.data.length == 0){
                            alert('Error username doesnt Exist');
                        }
                        else{
                            const isPasswordValid = await bcrypt.compare(oldpass, result.data[0].password);
                            console.log(result.data[0].password)
                            console.log(isPasswordValid)
                            if(isPasswordValid){
                                alert("Correct Password");
                                savePassword(pass,username)
                                
                            }
    
                            else{
                                alert("Incorrect Old Password");
                            }
                        }
            })
        }else{
            alert("The Passwords you typed don't match");
        }

    }
    const savePassword = async (pass, username) => {
        const result = {
            password: pass,
            username: username
        }
            await axios.put(`http://${serverIP}:3002/api/updatePassword`, result)
            .then(response => {
                console.log(response)
                alert("Password Changed")
            }).catch(error => {
                console.log(error)
            })

    }
    //#endregion

    //#region Use Effect
    useEffect(()=> {

        const fetchCsrfToken = async () => {
            try {
                await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
            };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
                });
            }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        
            const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
                console.log('Tokens set');
            })
            .catch(error => {
                console.error('Error setting tokens:', error);
            });
            }

            const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };
    },[])       
    //#endregion
    
    return(
        <IonPage >
            <IonHeader className='form_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent fullscreen>

            <div className='form_background'></div>
            <div className='form_background_img'></div>
            

                <div className="form_main">
                    <div className="form">
                            <img src={logo} className="form_logo"></img>
                            <IonInput className="form_input" ref ={oldPass} label="Old Password" type="password" labelPlacement="floating" fill ="outline" placeholder="Enter Old Password"></IonInput>
                            <IonInput className="form_input" ref ={PassRef} label="New Password" type="password" labelPlacement="floating" fill="outline" placeholder="Enter New Password"></IonInput>
                            <IonInput className="form_input" ref ={ConPassRef} label="Confirm Password" type="password" labelPlacement="floating" fill="outline" placeholder="Re-enter Password"></IonInput>
                            
                            <IonButton color='secondary' className="form_btn" onClick = {() => ResetPassword(PassRef.current.value, ConPassRef.current.value, oldPass.current.value, username)}>Change Password</IonButton>
                            <div className="reset_tags">
                                <a className="reset_tag" href="http://localhost:8100/home/forgetPass">Forgot Password</a>
                            </div>
                            
                    </div>

                </div>
            </IonContent>

            Change Password
        </IonPage>
    )
    }



export default ResetPass;