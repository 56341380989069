import { IonButton, IonButtons, IonIcon, IonImg, IonItem, IonList, IonSelect, IonSelectOption, IonTab, IonToolbar } from "@ionic/react";
import './Footer.css';
import logo from '../resources/logo/logo_transparent_edited.png';
import { useHistory } from 'react-router';


const Footer = () => {
  
  const history = useHistory();
  const switchPrivacy = () => {
    history.push({
      pathname: '/home/Privacy'
    })
  }

  const switchTerms = () => {
    history.push({
      pathname: '/home/Terms'
    })
  }


   return(
    <div className="footer">
      <div className="column3">
        <img src={logo} className="logo"></img>
      </div>
      <div/>
      <div className="column1">
        <p className="par">
          Explore for a variety of recipes, from easy to challenging, catering to all skill levels. Each recipe is carefully detailed to guide you through every step. Whether you're a novice or a pro, I've got the perfect dish for you. Let's cook up something delicious!
        </p>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">Categories</h5>
        <ul>
          <li><a href="#">Sweets</a></li>
          <li><a href="#">Drinks</a></li>
          <li><a href="#">Salads</a></li>
          <li><a href="#">Sauces</a></li>
          <li><p className="listpar">And a lot More</p></li>
        </ul>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">About Us</h5>
        <ul>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Contact Us</a></li>
          <li><a className="link" onClick={switchPrivacy}>Privacy Policy</a></li>
          <li><a className="link" onClick={switchTerms}>Terms and Conditions</a></li>
        </ul>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">Contact Us</h5>
        <ul>
          <li><a href="#">Instagram</a></li>
          <li><a href="#">Facebook</a></li>
          <li><a href="#">Twitter</a></li>
          <li><a href="#">Facebook</a></li>
          <li><a href="#">Facebook</a></li>
        </ul>
      </div>

      <div className="owner">
        <h5 className="title">Owned by CEDARIO LTD</h5>
      </div>
    </div>
   )
    

}

export default Footer;