import { IonButton, IonButtons, IonPopover, IonAlert, IonModal, IonGrid, IonRow, IonCol, IonHeader, IonTitle, IonIcon, IonImg, IonItem, IonList, IonSelect, IonSelectOption, IonTab, IonToolbar, IonContent} from "@ionic/react";
import {searchOutline, person} from 'ionicons/icons';
import './NavBar.css';
import logo from '../resources/logo/logo_transparent_edited.png';
import "@fontsource/abril-fatface";
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';

const NavBar = (props) => {

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const subscribed = "Is Subscribed"
  const startDate = "2/2/2022"
  const endDate = "2/2/2023"
  const isSubscribed = true
  const history = useHistory();

  const onClickNav = (name) => {

    switch (name) {
      case 'home':
        window.history.replaceState(null, null, '/');
        window.location.reload();
        break;
      case 'aboutus':
        history.push({
          pathname: '/home/aboutus'
        })
        break;
      case 'contact':
        history.push({
          pathname: '/home/contact'
        })
      default:
        break;
    }

  }



   return(
    <IonToolbar className="navbar_header">
      <IonButtons slot="start" className="navbar_btns">
          <img src={logo} className="logo_img"></img>
          <IonButton className="navbar_btn" onClick={()=>{onClickNav('home')}}>
            Home
          </IonButton>
          <IonButton className="navbar_btn" onClick={()=>{onClickNav('aboutus')}}>
            About Us
          </IonButton>
          <IonButton className="navbar_btn" onClick={()=>{onClickNav('contact')}}>
            Contact
          </IonButton>
          {/* <IonButton>
            <IonIcon icon={searchOutline}></IonIcon>
          </IonButton> */}
           
        </IonButtons>
          {props.showLogin ? 
          <IonButtons slot="end" >
            <IonButton className="navbar_btn" onClick={props.onLoginClick}>
              Login
            </IonButton>
            <IonButton className="navbar_btn" onClick={props.onSubscribeClick}>
              Subscribe
            </IonButton>
          </IonButtons>   
          : props.showAccount ?
          <IonButtons slot="end">
              <IonButton id="auto-trigger" onClick={() => setIsPopoverOpen(true)} className="navbar_btn"> 
                <IonIcon icon={person}></IonIcon>
              </IonButton>
              <IonPopover isOpen={isPopoverOpen} onDidDismiss={() => setIsPopoverOpen(false)}  trigger="auto-trigger" size="auto" dismissOnSelect={true}>
                  <IonContent>
                    <IonList>
                      <IonItem button={true} onClick={() => setShowAlert(true)} >Logout</IonItem>
                      <IonItem button={true} onClick={() => setIsModalOpen(true)} >Subscription</IonItem>
                    </IonList>
                  </IonContent>
              </IonPopover>
              <IonAlert isOpen={showAlert} onDidDismiss={() => setShowAlert(false)} header={'Confirmation'} message={'Are you sure?'}
                buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => {
                  // Handle cancel action
                    console.log('Cancelled');
                  }
                },
                {
                  text: 'Yes',
                  handler: () => {
                    props.onLogout()
                  }
                }
                ]}
                />
                <IonModal isOpen={isModalOpen}>
                  <IonHeader>
                    <IonToolbar>
                      <IonTitle>Subscription</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => setIsModalOpen(false)}>Close</IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className="ion-padding">
                    <IonList>
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <IonItem>Subscription Details</IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem>{subscribed}</IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem>Start Date</IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem>{startDate}</IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem>End Date</IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem>{endDate}</IonItem>
                          </IonCol>
                        </IonRow>
                        {isSubscribed ?
                        
                        <IonRow>
                          <IonCol>
                            <IonButton>Cancel Subscription</IonButton>
                          </IonCol>
                        </IonRow>
                      
                      : 
                      
                      <IonRow>
                        <IonCol>
                          <IonButton>Start Subscription</IonButton>
                        </IonCol>
                      </IonRow>
                      }
                      </IonGrid>
                    </IonList>
                  </IonContent>
                </IonModal>
          </IonButtons>
           : null}
        
       
    </IonToolbar>
   )
    

}

export default NavBar;