import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonImg, IonIcon } from '@ionic/react';
import './RecipeCard.css';
import pic from '../resources/recipes/chorizo-mozarella-gnocchi-bake-cropped.jpg'
import plate1 from '../resources/recipes/rice.png'
import plate2 from '../resources/special.png'
import { peopleOutline, speedometerOutline, timeOutline } from 'ionicons/icons';
// import imgg from 'drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ'

const RecipeCard = (props) => {

    // console.log(recipe.recipe.state);
    if(props.recipe.state.images != null){
      // console.log(recipe.recipe.state.images[0])
    }

    // const img = require("drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ")
    return(


      <div className='recipe_card' onClick={()=>{props.onClickRecipe(props.recipe.getId())}}>
        
        { props.recipe.state.images==null ?(
          <img className='recipe_card_image' src={plate2}></img>
        ):(
          <img className='recipe_card_image' src={props.recipe.state.images[0]}></img>
         )}
        {/* <img className='recipe_card_image' src={plate1}></img> */}

        <div className='recipe_card_header'>
          {props.recipe.getTitle()}
        </div>

        <div className='recipe_card_footer'>
          <div className='footer_btn_left'>
            <IonIcon className='footer_icon' icon={timeOutline}/>
            <div className='footer_text'>{props.recipe.getTime() ? props.recipe.getTime(): '-'}</div>
          </div>
          <div className='footer_btn_middle'>
            <IonIcon className='footer_icon' icon={peopleOutline}/>
            <div className='footer_text'>{props.recipe.getPortion() ? props.recipe.getPortion(): '-'}</div>
          </div>
          <div className='footer_btn_right'>
            <IonIcon className='footer_icon' icon={speedometerOutline}/>
            <div className='footer_text'>{props.recipe.getDifficulty() ? props.recipe.getDifficulty(): '-'}</div>
          </div>
        </div>

      </div>


    )
    
}

export default RecipeCard;