//#region Imports
//Import Ionic components
import { IonContent, IonHeader, IonInput, IonItem, IonButton, IonPage } from "@ionic/react"
//Immport Components
import NavBar from "../components/NavBar";
//Import Axios
import axios from 'axios'
//Import React Components
import bcrypt from "bcryptjs-react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import logo from '../resources/logo/logo_transparent_edited.png'
//#endregion



const Verify = () => {
    
    //#region Variables
    const serverIP = "localhost"
    var urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const username = urlParams.get('username');
    console.log(email)
    console.log(username)
    //#endregion

    //#region Functions
    const verifyEmail = async (email, username) => {
        await axios.get(`http://${serverIP}:3002/api/credentials?username=${encodeURIComponent(username)}`)
                .then(async (result) => {
                    if(result.data.length == 0){
                        alert('Incorrect Username');
                        console.log(username);
                        console.log(email)
                    }
                    else{
                        const isEmailValid = await bcrypt.compare(result.data[0].email,email);
                        console.log(result.data[0].email)
                        console.log(isEmailValid)
                        if(isEmailValid){
                            alert("Correct Email");
                            updateVerification(username);
                        }

                        else{
                            alert("Incorrect Email");
                        }
                    }
        })
    }

    const updateVerification = async (username) => {
        await axios.put(`http://${serverIP}:3002/api/updateVerify`,{uname: username})
            .then(async (result) => {

            })
    }
    //#endregion

    //#region Use Effect
    useEffect(()=> {

        const fetchCsrfToken = async () => {
            try {
              await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
              console.error('Error fetching CSRF token:', error);
            }
          };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
              .then(response => {
                // console.log('Access token refreshed');
              })
              .catch(error => {
                // console.error('Error refreshing token:', error);
              });
          }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
          
      
          const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
              console.log('Tokens set');
            })
            .catch(error => {
              console.error('Error setting tokens:', error);
            });
          }

          const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };



    },[])
    //#endregion

    return(
        <IonPage>
            <IonHeader className='home_header'>
                <NavBar></NavBar>
            </IonHeader>

            <IonContent align="center" fullscreen>
            <div className='form_background'></div>
            <div className='form_background_img'></div>
            <div className="form_verify">
            <IonButton className="verify_btn" onClick={() => verifyEmail(email,username)} color="secondary">Verify your account</IonButton>

            </div>

            </IonContent>
        </IonPage>
    )
}







export default Verify;